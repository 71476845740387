import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'
import querystring from 'querystring'

/**
 * @param {Object} args - Argumentos a pasar en query
 * @returns {Promise}
 */
export function getSessionSummaries (args) {
  const params = querystring.stringify(args)

  return axios.get(API('session-summaries', params), {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getJwtString()
    }
  })
}

/**
 * Envía una petición PUT al backend session-summaries
 * @param {Object} data - body de la petición PUT
 * @returns {Promise}
 */
export function putSessionSummary (data) {
  return axios.put(API('session-summaries'), data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getJwtString()
    },
    body: JSON.stringify(data)
  })
}

/**
 * Envía una petición POST al backend session-summaries
 * @param {Object} data - body de la petición POST
 * @returns {Promise}
 */
export function postSessionSummary (data) {
  return axios.post(API('session-summaries'), data, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: getJwtString()
    },
    body: JSON.stringify(data)
  })
}
