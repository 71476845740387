import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import GET from '../dao/get'

const useRanks = (defaultValue, options = {}) => {
  const [ranks, setRanks] = useState(defaultValue)

  useEffect(() => {
    const queryParams = options.treeFormat ? 'format=treeFormat' : ''
    GET('ranks', queryParams).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else if (res.body !== null) {
        setRanks(res.body)
      }
    })
  }, [])

  return [ranks, setRanks]
}

export default useRanks
