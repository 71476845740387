const DocumentType = [
  { value: 'RC', label: 'RC: Registro Civil' },
  { value: 'TI', label: 'TI: Tarjeta de Identidad' },
  { value: 'CC', label: 'CC: Cédula de Ciudanía' },
  { value: 'CE', label: 'CE: Cédula de Extrangeria' },
  { value: 'PA', label: 'PA: Pasaporte ' },
  { value: 'MS', label: 'MS: Menor sin Identificación' },
  { value: 'AS', label: 'AS: Adulto sin Identificación' },
  { value: 'PE', label: 'PE: Permiso Especial de Permanencia' },
  { value: 'CN', label: 'CN: Certificado Nacido Vivo' },
  { value: 'CD', label: 'CD: Carnét Diplomático' },
  { value: 'SC', label: 'SC: Salvoconducto' },
  { value: 'DE', label: 'DE: Documento Extrangero' },
  { value: 'PT', label: 'PT: Permiso por Proteccion Temporal' },
  { value: 'SI', label: 'SI: Sin Identificación' }
]

export default DocumentType
