import axios from 'axios'
import { getJwtString } from '../../components/Auth'
import API from '../../components/API'

axios.defaults.headers.common['Content-Type'] = 'application/json'

/**
 * Crea una solicitud de remisión de una sesión
 * @param {Object} data - Datos de solicitud de remisión
 * @returns {Promise}
 */
export function postRemissionRequest (data) {
  return axios.post(API('remission-requests'), data, {
    headers: {
      Authorization: getJwtString()
    }
  })
}

/**
 * Elimina la solicitud de remisión de una sesión
 * @param {Object} session_id
 */
export function deleteRemissionRequest (sessionId) {
  return axios.delete(API('remission-requests'), { data: { session_id: sessionId } }, {
    headers: {
      Authorization: getJwtString()
    }
  })
}
