const LocationStatus = [
  { value: "área", label: "Área de operaciones" },
  { value: "batallón", label: "Batallón" },
  { value: "base", label: "Base Militar" },
  { value: "escuela_militar", label: "Escuela de formación militar" },
  { value: "centro_penitenciario", label: "Centro penitenciario o de reclusión" },
  { value: "ciudad", label: "Ciudad" },
  { value: "cabecera_municipal", label: "Cabecera municipal" },
  { value: "zona_rural", label: "Zona rural" },
  { value: "casa_fiscal", label: "Casa fiscal" },
]

export default LocationStatus
